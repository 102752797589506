import { mapActions, mapState } from 'vuex'

export default {
    name: 'RoleIndex',
    data: () => ({
        instances: [],
        colors: [],
        roles: [],
        selected: [],
        errors: {},
        error: false,
        message: '',
        showCreate: false,
        loadingCreate: false,
        loadingRoles: true,
        loadingSaveRole: false,
        role: {
            name: '',
            description: ''
        },
        menus: [],
        charts: []
    }),
    methods: {
        ...mapActions('role', ['ActionFindRoles', 'ActionCreateRole', 'ActionUpdateRoleMenu', 'ActionInstances']),
        ...mapActions('menu', ['ActionFindMenus']),
        ...mapActions('chart', ['ActionFindCharts']),
        async getData(selected = null) {
            
            let payload = {
                institution_id: this.acesso.instituicao.id,
                find_columns: {
                    active: 1
                }
            };
            
            await this.ActionFindRoles(payload)
                .then((res) => {
                    this.roles = res.data
                    if(selected) {
                        var role = this.roles.filter(function(e){
                            return e.id == selected.id
                        })

                        this.selected = role[0]
                        this.showCreate = false
                    }
                })
            await this.ActionFindMenus()
                .then((res) => {
                    this.menus = res.data;
                })
            await this.ActionInstances()
                .then((res) => {
                    this.instances = res.data
                })
            await this.ActionFindCharts()
                .then((r) => {
                    this.charts = r.data
                })
            
            this.loadingRoles = false
        },
        savePermissions() {

            this.error = false
            this.message = ''
            this.loadingSaveRole = true

            Object.assign(this.selected, {institution_id: this.acesso.instituicao.id})

            this.ActionUpdateRoleMenu(this.selected)
                .then((res) => {
                    this.message = res.message
                    //this.getData()
                })
                .catch((error) => {
                    this.error = true
                    this.message = error.message
                    if(error.code === 422) {
                        this.errors = error.errors
                    } else {
                        
                    }
                })
                .finally(() => {
                    this.loadingSaveRole = false
                })
        },  
        createRole() {

            this.error = false;
            this.errors = [];
            this.message = '';

            Object.assign(this.role, {institution_id: this.acesso.instituicao.id, level: 0})

            this.ActionCreateRole(this.role)
                .then((res) => {
                    this.getData(res.data);
                })
                .catch((error) => {
                    this.error = true
                    this.message = error.message

                    if(error.code === 422) {
                        this.errors = error.errors
                    } else {
                        
                    }
                    this.loadingCreate = false
                })
        }
    },
    created() {
        this.getData()
    },
    computed: {
        ...mapState('auth', ['acesso'])
    },
    watch: {
        showCreate(val){
            if(val){
                this.error = false;
                this.errors = [];
                this.message = '';
                this.role = {
                    name: '',
                    description: ''
                };
            }
        }
    }
}